.triangle_down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #d3d5da;
  font-size: 0;
  line-height: 0;
  float: left;
}
.triangle_up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 6px solid #d3d5da;
  font-size: 0;
  line-height: 0;
  float: left;
}
