.custom-negative-chart-bar {
  --color: transparent;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  position: relative;
}

.custom-negative-chart-bar:hover {
  --color: transparent;
}

.custom-negative-chart-bar-container {
  width: 100%;
  align-self: center;
}

.last-bar-positive {
  background: #085096 !important;
}

.last-bar-negative {
  background: #ff2a2a !important;
}

.positive {
  background: #bbcfea;
}

.positive:hover {
  background: #72a1d5;
}

.negative {
  transform: rotate(-180deg);
  transform-origin: bottom;
  background: #ffc9c9;
}

.negative-reverse {
  background: #ffc9c9;
}

.negative-reverse:hover {
  background: #ff2a2a;
}

.positive-reverse {
  transform: rotate(-180deg);
  transform-origin: bottom;
  background: #bbcfea;
}
.positive-reverse:hover {
  background: #085096;
}
.negative:hover {
  background: #e68688;
}

.my-negative-chart tr::before {
  content: "";
  width: 1px;
  height: 20px;
  border: 1px dotted #ccc;
  position: absolute;
  bottom: -20px;
  left: -2.5%;
}

.my-negative-chart tr:last-child::after {
  content: "";
  width: 1px;
  height: 20px;
  border: 1px dotted #ccc;
  position: absolute;
  bottom: -20px;
  right: -2.5%;
}

.my-negative-chart td div:first-child {
  border-radius: 5px 5px 0 0;
}

.my-negative-chart {
  width: 100%;
  display: flex;
  padding-left: 10%;
  align-items: stretch;
  position: relative;
}

.my-negative-chart .column tbody td {
  animation: moving-bars 0.5s linear;
}

@keyframes moving-bars {
  0% {
    transform: scaleY(0);
  }
  30% {
    transform: scaleY(100%);
  }
}

.my-negative-chart tbody {
  gap: 1%;
}

.my-negative-chart tr {
  gap: 5%;
}

.custom-negative-chart-legend-bar {
  width: 10%;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: bold;
  color: #ccc;
}

.year-xaxis {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #c9cacb;
}

.custom-negative-chart-legend-bar span {
  color: #a3aed0;
  font-weight: 400;
  font-size: 12px;
}

.custom-negative-chart-legend-bar .first-legend {
  margin: 0;
}

.custom-negative-chart-bar-tooltip {
  display: none;
}

.custom-negative-chart-bar:hover .custom-negative-chart-bar-tooltip {
  content: "";
  background: white;
  position: absolute;
  left: -2rem;
  width: 7rem;
  display: inline;
  border-radius: 10px;
  border: 1px solid #d3d5da;
  z-index: 9999;
  outline: none;
}

.custom-negative-chart-bar-tooltip-test {
  position: absolute;
  top: -50%;
  left: 0;
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 9999;
  display: none;
}

.custom-negative-chart-bar:hover .custom-negative-chart-bar-tooltip-test {
  display: block;
}

.custom-negative-chart-bar:hover .positive-bar-tooltip {
  top: -50px;
}

.custom-negative-chart-bar:hover .negative-bar-tooltip {
  bottom: 0;
}

.custom-negative-chart-bar-wrapper {
  position: relative;
  padding: 8px;
  width: 100%;
}

.tooltip-indicator-down {
  position: absolute;
  left: 38%;
  bottom: -10%;
}

.tooltip-indicator-up {
  position: absolute;
  left: 38%;
  top: -10%;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #d3d5da;
  font-size: 0;
  line-height: 0;
  float: left;
}

.triangle-up {
  transform: rotate(180deg);
}
