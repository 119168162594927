.card {
  max-width: 380px;
}

@media (min-width: 768px) {
  .card {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1000px) {
  .card {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1280px) {
  .card {
    width: calc(33% - 20px);
  }
}

@media (min-width: 1600px) {
  .card {
    width: calc(25% - 30px);
  }
}
