.form_checkBox span {
  height: 24px;
  width: 24px;
  padding: 0;
  border-radius: 8px;
}

.defaultLink {
  color: #fff;
  transition: all 250ms ease-in-out;
}

.defaultLink:hover p,
.defaultLink:focus p {
  color: #6ca2d6 !important;
}

.defaultLink:hover,
.defaultLink:focus {
  color: #6ca2d6 !important;
}

.menu {
  filter: drop-shadow(1px 1px 1px #4700b3);
  width: 300px;
}

.menu > ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu > li {
  display: block;
}

.menu > ul,
li {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.buttonMenu {
  -webkit-appearance: button;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customSelect > * {
  background: transparent;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .customGrid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (min-width: 1800px) {
  .customGrid {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

.full {
  width: "100%";
  height: "100%";
}

.text-border {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  padding-left: 10px; /* Adjust padding to accommodate border width */
  padding-right: 10px; /* Adjust padding to accommodate border width */
}

.text-border::before,
.text-border::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center the borders vertically */
  height: 100%; /* Set border height to match text height */
}

.text-border::before {
  left: 0;
  width: 2px; /* Adjust width for border thickness */
  background-color: #ccc; /* Set border color */
}

.text-border::after {
  right: 0;
  width: 2px; /* Adjust width for border thickness */
  background-color: #ccc; /* Set border color */
}

.gradient-background {
  background: linear-gradient(to bottom, #123456, #654321);
}
