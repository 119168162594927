.note {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 24px;

  width: 256px;
  height: 210px;
  background: url("../../../assets/image/note-base.png") center no-repeat;
}

.note::before {
  content: "";
  position: absolute;
  top: 0;
  left: 47%;
  width: 30px;
  height: 12px;
  display: block;

  transform: rotate(80deg);

  background: url("../../../assets/svg/curve_arrow.svg") center no-repeat;
}

.noteText {
  max-width: 184px;
  width: 100%;

  font-size: 20px;
  font-weight: 500;
  font-family: "Intruding Cat";
  line-height: 24px;
  text-align: center;

  transform: rotate(3deg);
}

@media (min-width: 1235px) {
  .note::before {
    top: 45%;
    left: -5px;
    transform: none;
  }
}
