.mobileMenuItem {
  width: fit-content;
  position: relative;

  margin-right: 20px;
}

.mobileMenuItem.active::after {
  content: "";
  position: absolute;

  bottom: 1px;
  left: 50%;
  width: 20px;
  height: 4px;
  display: block;

  transform: translateX(-50%);

  background-color: #fff;
  color: #fff;

  border-radius: 3px;
}

.slider {
  width: 100%;
  margin-right: 20px;
}

.slider div {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;

  /* gap: 20px; */
}

.slide {
  width: fit-content !important;
}
