.textWrapper {
  position: absolute;

  top: 52%;
  left: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.score {
  font-size: 40px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;

  color: #0a0a0a;
}

.label {
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  font-weight: 100;
  line-height: 12px;
  text-align: center;

  color: #737373;
}
