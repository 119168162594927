.link {
  position: relative;
}

.wrapper {
  opacity: 0.3;
  transition: all 250ms ease-in-out;
}

.link:hover .wrapper {
  opacity: 1;
}

.soonBadge {
  position: absolute;
  top: -5px;
  right: -8px;
  width: fit-content;

  background-color: #ff4b4bcc;
  padding: 2px 4px;
  border-radius: 30px;

  color: #fff;
  font-size: 8px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  text-align: center;
}

.lower {
  top: 0;
}

.mobileLinksWrapper .wrapper {
  opacity: 1;
  color: #fff;
}

.mobileLinksWrapper .wrapper:hover,
.mobileLinksWrapper .wrapper:focus {
  color: #6ca2d6 !important;
  fill: #6ca2d6 !important;
}

.active .wrapper {
  opacity: 1 !important;
  color: #fff;
}

.disabled {
  pointer-events: none;
}
