.custom-chart-bar {
  --color: #eff5fb;
  margin: 0 !important;
  padding: 0 !important;
}

.custom-chart-bar:hover {
  --color: #085096;
}

.last-bar {
  --color: #c2d7ec;
}

.my-chart tr::before {
  content: "";
  width: 1px;
  height: 20px;
  border: 1px dotted #ccc;
  position: absolute;
  bottom: -20px;
  left: -2.5%;
}

.my-chart tr:last-child::after {
  content: "";
  width: 1px;
  height: 20px;
  border: 1px dotted #ccc;
  position: absolute;
  bottom: -20px;
  right: -2.5%;
}

.my-chart td {
  border-radius: 5px 5px 0 0;
}

.my-chart {
  width: 100%;
  display: flex;
  padding-left: 5%;
  align-items: stretch;
  position: relative;
}

.my-chart .column tbody td {
  animation: moving-bars 0.5s linear;
}
@keyframes moving-bars {
  0% {
    transform: scaleY(0);
  }
  30% {
    transform: scaleY(100%);
  }
}

.my-chart tbody {
  gap: 1%;
}

.custom-chart tr {
  gap: 5%;
}

.custom-chart-legend-bar {
  width: 5%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  bottom: 25px;
  left: 0;
  font-size: 0.8em;
  font-weight: bold;
  color: #ccc;
}

.year-xaxis {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #c9cacb;
}

.custom-chart-legend-bar span {
  color: #a3aed0;
  font-weight: 400;
  font-size: 12px;
}

.custom-chart-legend-bar .first-legend {
  margin: 0;
}

.custom-chart-bar-tooltip {
  display: none;
}

.custom-chart-bar:hover .custom-chart-bar-tooltip {
  content: "";
  background: white;
  position: absolute;
  top: -50px;
  left: -2rem;
  width: 6rem;
  display: inline;
  border-radius: 10px;
  border: 1px solid #d3d5da;
  z-index: 1000;
  outline: none;
}

.custom-chart-bar-wrapper {
  position: relative;
  padding: 8px;
  width: 100%;
}

.tooltip-indicator {
  position: absolute;
  left: 38%;
  bottom: -15%;
}

.triangle_down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #d3d5da;
  font-size: 0;
  line-height: 0;
  float: left;
}
