.link {
  position: relative;
  opacity: 1;
}

/* .link :not(.soonBadge) {
  opacity: 0.3;
} */

.wrapper {
  opacity: 1;
  transition: all 250ms ease-in-out;
  white-space: nowrap;
}

.link:hover .wrapper {
  opacity: 1;
}

.soonBadge {
  position: absolute;
  top: -5px;
  right: -8px;
  width: fit-content;

  background-color: #ff4b4bcc;
  padding: 2px 4px;
  border-radius: 30px;

  color: #fff;
  font-size: 8px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  text-align: center;
}

.active .wrapper {
  opacity: 1 !important;
}

.disabled {
  pointer-events: none;
}
