.chakra-modal__content-container {
  display: flex !important;
  align-items: center !important;
}

.highlight_link {
  color: #0a64bc;
}

.scroll_container {
  height: 500px !important;
  overflow: auto !important;
}
.scroll_container::-webkit-scrollbar {
  width: 8px;
}

.scroll_container::-webkit-scrollbar-track {
  width: 8px !important;
}

.scroll_container::-webkit-scrollbar-thumb {
  width: 8px !important;
}
