.itemWrapper {
  min-width: 120px;
  padding: 20px 20px 20px 15px;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f9fafb;
  box-sizing: content-box;
}

.list {
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  scrollbar-width: none;

  gap: 10px;
}

/* .list {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  gap: 10px;
} */
