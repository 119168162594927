.stocksSearchWrapper {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.stockSearchItem {
  transition: all 250ms ease-in-out;
}

.stockSearchItem:hover,
.stockSearchItem:focus {
  color: #6ca2d6;
}

.commonSearch {
  position: absolute;
  top: 45px;
  width: 91.2%;
  background-color: #0564b8;
  color: #fff;
  z-index: 200;
}

@media (max-width: 425px) {
  .commonSearch {
    position: absolute;
    top: 45px;
    width: 87.9%;
    background-color: #0564b8;
    color: #fff;
    z-index: 200;
  }
}
