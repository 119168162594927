@media screen and (min-width: 448px) {
  .home-bg {
    height: 490px !important;
  }
}

html {
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 100px;
}
