.root {
  color: #fff;
}

.root:hover {
  color: #6ca2d6 !important;
  fill: #6ca2d6 !important;
}

.withTransition {
  transition: all 250ms ease-in-out;
}
