.clamp {
  display: -webkit-box;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial;
  line-clamp: 2;
  height: 45px;
}

@media (min-width: 768px) {
  .clamp {
    max-width: calc(100vw - 588px);
  }
}
