.info_icon {
  font-weight: 600 !important;
  font-size: 14px;
  margin-left: 4px;
}

.info_icon_mini {
  font-weight: 400 !important;
  font-size: 12px;
  margin-left: 4px;
  color: #0a64bc;
}

.active_bar {
  box-shadow: 0px 3px 8px rgba(34, 34, 34, 0.05);
  background-color: white;
}

.bold_detailed_para {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.bold_bar_text {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
}

.circle_icon {
  height: 20px !important;
  width: 20px !important;
  display: inline-block !important;
}

.parent_card_height {
  overflow: auto;
  min-height: 10px;
  border-radius: 20px;
}

.thermomtr_element {
  height: 90px;
  width: 425px !important;
  margin-top: 12px;
}

.bold_para {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.bold_para_16font {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.word_spacing {
  word-spacing: 3px;
  padding-right: 14px;
}

.green_bar div {
  background-color: #76cd0c !important;
}

.red_bar div {
  background-color: #ff4b4b !important;
}

.mt_adjust {
  margin-top: -184px !important;
}

.parent_card_height {
  width: 100%;
}

.bold_detailed_para_16font {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

@media screen and (max-width: 600px) {
  .info_icon {
    font-weight: 300;
    margin-left: 6px;
    color: #0a64bc;
  }
  .parent_card_height {
    height: auto !important;
    width: 100% !important;
    box-shadow: none;
    border-radius: 20px;
  }
  .thermomtr_element {
    height: 74px;
    margin-top: 12px;
  }
  .bold_bar_text {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }
  .circle_icon {
    height: 12px !important;
    width: 12px !important;
    display: inline-block !important;
  }
  .bold_detailed_para {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .bold_detailed_para_16font {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .word_spacing {
    word-spacing: 3px;
    padding-right: 10px;
  }

  .growth_card,
  .health_card,
  .momentum_card {
    margin-top: 0px !important;
  }
}

.grid {
  --gap: 2.5em;
  --columns: 1;
  margin: 0 auto;
  columns: 1;
  gap: 2.5em;
  width: 375px !important;
}

.grid > * {
  break-inside: avoid;
  margin-bottom: 2em;
  width: 100%;
}

@supports (grid-template-rows: masonry) {
  .grid {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: masonry;
    grid-auto-flow: dense;
  }
}

.featured {
  grid-column: span 2;
}

.content {
  padding: 2em;
  box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
  background: white;
}

@media screen and (max-width: 600px) {
  .grid {
    columns: 1;
    gap: 2em;
  }
  .grid > * {
    break-inside: avoid;
    margin-bottom: 2em;
  }
  .chakra-modal__content-container .chakra-slide.chakra-modal__content {
    width: 255px !important;
  }
}
