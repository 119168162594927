.scrollable_box {
  overflow: auto !important;
  scroll-behavior: smooth !important;
}
.scrollable_box_hidden {
  display: none !important;
  flex-wrap: wrap !important;
  overflow: hidden !important;
}

.scrollable_box::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollable_box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollable_box::-webkit-scrollbar-thumb {
  background-color: #8cb9e5;
  /* outline: 1px solid #59a4ca; */
  border-radius: 15px;
}

.scrollable_box::-webkit-scrollbar-thumb:hover {
  background-color: #0a64bc;
}
